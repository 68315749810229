<!-- eslint-disable max-len -->
<template>
    <div style="padding: 10px">
        <div style="background: #fff; border-radius: 8px; padding: 20px;">
            <div class="query-c">
                 <span>用户名：</span>
                <Input search placeholder="请输入用户名" style="width: auto" v-model="username"/>
                <span>状态：</span>
                <Select v-model="statusValue" multiple style="width: 200px;" >
                <Option v-for="(statusItem, index) in allStatusList" :key="index" :value="statusItem.code"> {{ statusItem.value }}</Option>
                </Select>
                <span>时间：</span>
                <DatePicker @on-change="dateChange" type="datetimerange" placement="bottom-end" placeholder="Select date" style="width: 200px" />
                    <button style="margin-left: 25%;" @click="handleSearch" class="search-button">查询</button>
                    &nbsp;
                    <button @click="clearSearch" class="search-button">重置</button>
            </div>
            <br>
            <Table max-height="440"  border highlight-row  stripe :columns="columns1" :data="data1"></Table>
            <br>
            <Page :total="total" show-sizer  prev-text="上一页" @on-change="change" @on-page-size-change="pageSizeCall" next-text="下一页" />
        </div>
    </div>
</template>

<script>
import { scanLogList, statusList } from '@/api/scan.js'

export default {
    name: 'scanLoginLog',
    data() {
        return {
            statusValue: [],
            allStatusList: [],
            username: '',
            startTime: '',
            endTime: '',
            pageSize: 10,
            firstResult: 0,
            content: '',
            total: 0,
            columns1: [
                {
                    title: '扫码id',
                    key: 'scanId',
                    render: (h, params) => h('div', [
                        // 显示的位置上方
                        h('Tooltip', {
                            props: { placement: 'top', transfer: true },
                        }, [
                            h('span', {
                                style: {
                                    display: 'inline-block',
                                    width: '100px',
                                    overflow: 'hidden',
                                    // 同样在这里隐藏溢出内容
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                },
                            }, params.row.scanId),
                            // 显示的内容
                            h('span', {
                                slot: 'content',
                                style: { whiteSpace: 'normal', wordBreak: 'break-all' },
                            }, params.row.scanId),
                        ]),
                    ]),
                },
                {
                    title: '操作人',
                    key: 'userName',
                },
                {
                    title: '扫码时间',
                    key: 'scanTime',
                },
                {
                    title: '过期时间',
                    key: 'expirationTime',
                },
                {
                    title: '设备类型',
                    key: 'deviceType',
                },
                {
                    title: '设备信息',
                    key: 'deviceInfo',
                },
                {
                    title: 'IP',
                    key: 'ipAddress',
                },
                {
                    title: 'IP属地',
                    key: 'location',
                    render: (h, params) => h('div', [
                        h('Tooltip', {
                            props: { placement: 'top', transfer: true },
                        }, [
                            h('span', {
                                style: {
                                    display: 'inline-block',
                                    width: '100px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                },
                            }, params.row.location),
                            h('span', {
                                slot: 'content',
                                style: { whiteSpace: 'normal', wordBreak: 'break-all' },
                            }, params.row.location),
                        ]),
                    ]),
                },
                {
                    title: '状态',
                    key: 'status',
                },
                {
                    title: '错误信息',
                    key: 'errorMessage',
                },
                {
                    title: '创建时间',
                    key: 'createDatetime',
                },
                {
                    title: '修改时间',
                    key: 'modifyDatetime',
                },
            ],
            data1: [],
        }
    },
    methods: {
        getStatusList() {
            statusList({
            }).then(res => {
                this.allStatusList = res.retVal
            })
        },
        handleSearch() {
            this.getPage()
        },
        clearSearch() {
            this.startTime = ''
            this.endTime = ''
            this.username = ''
            this.statusValue = []
        },
        dateChange(val1) {
            this.startTime = val1[0]
            this.endTime = val1[1]
        },
        calculateStartData(currentPage, pageSize) {
            let startData = (currentPage - 1) * pageSize
            return startData
        },
        pageSizeCall(val) {
            this.pageSize = val
        },
        change(val) {
            this.firstResult = this.calculateStartData(val, this.pageSize)
            this.getPage()
        },
        getPage() {
            const str = this.statusValue.join(',')
            scanLogList({
                userName: this.username,
                statusList: str,
                startTime: this.startTime,
                endTime: this.endTime,
                pageSize: this.pageSize,
                firstResult: this.firstResult,
            }).then(res => {
                this.data1 = res.retVal.datas
                this.total = res.retVal.totalRows
            })
        },
    },
    mounted() {
        this.getStatusList()
        this.getPage()
    },
}
</script>

<style scoped>
.search-button {
    padding: 8px 16px;
    background-color: #1890ff;
    color: #fff;
    border: none;
    border-radius: 4px;
}
</style>