import request from '@/utils/request'

export function scanLogList(data) {
    return request.get('/diary/scanLogin/page', { params: data })
}


export function statusList(data) {
    return request.get('/diary/scanLogin/statusList', { params: data })
}
